.adminHeader .pass_show {
  position: relative;
}

.adminHeader .ptxt {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: gray;
  transition: color 0.3s;
}

.adminHeader .ptxt:hover {
  color: black;
}

@media (max-width: 769px) {
  .adminHeader .navbar .navbar-nav > li > a {
    padding: 10px 15px;
    font-size: 14px;
  }
  .adminHeader .navbar .dropdown-menu > li > a {
    font-size: 14px;
  }

  .adminHeader .navbar .navbar-right {
    display: none;
  }

  .adminHeader .change-password-modal-content {
    width: fit-content;
    margin-left: auto;
  }

  .adminHeader .admin-text {
    display: block;
    font-size: 15px;
    color: #fff;
    margin-right: 10px;
    margin-left: 10px;
  }
  .adminHeader .mob-admin-menu {
    display: none;
  }
}

.adminHeader .change-password-modal-content {
  margin: auto;
}

@media (max-width: 1024px) and (min-width: 600px) {
  .adminHeader .navbar .navbar-nav > li > a {
    padding: 10px 15px;
    font-size: 14px;
  }
  .adminHeader .navbar .dropdown-menu > li > a {
    font-size: 14px;
  }

  .adminHeader .navbar .navbar-right {
    display: none;
  }

  .adminHeader .change-password-modal-content {
    width: 80%;
    margin: auto;
  }

  .adminHeader .admin-text {
    display: block;
    font-size: 15px;
    color: #fff;
    margin: 0 10px;
  }

  .adminHeader .modal-footer {
    display: flex;
    justify-content: center;
  }
}

.adminHeader .change-password-modal-content-dialog {
  max-width: 380px;
  margin: 30px auto;
}

.adminHeader .change-password-modal-content .modal-footer .btn {
  margin: 0 5px;
}

@media (max-width: 600px) {
  .adminHeader .change-password-modal-dialog {
    max-width: 90%;
    margin: 10px auto;
  }

  .adminHeader .change-password-modal-content {
    padding: 15px;
  }

  .adminHeader .modal-footer {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}

.adminHeader .dropdown-menu li a {
  padding: 10px !important;
  margin: 5px !important;
}
.adminHeader .dropdown-menu {
  margin-right: 5px !important;
}

.adminHeader .custom-modal {
  max-width: 420px;
  width: 100%;
}

.adminHeader .dropdown-menu li a {
  padding: 10px !important;
  margin: 5px !important;
}
.adminHeader .dropdown-menu {
  margin-right: 5px !important;
}
