.ps-otpverification {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
 
.ps-otpverification {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;
}
 
#loginbackground-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: brightness(50%);
}
 
.login-container {
  max-width: 100%;
  min-width: 400px !important;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
 
.panel-heading {
  padding: 20px;
  background-color: #5b8c68;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: center;
  color: #fff;
}
 
.LogoImg {
  width: 150px;
  margin-bottom: 10px;
}
 
.ps-otpverification.panel-body {
  padding: 20px;
}
 
.otp-inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}
.otp-field {
  width: 50px;
  height: 50px;
  font-size: 18px;
  text-align: center;
  border: 2px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}
 
.otp-field:focus {
  border-color: #5b8c68;
}
 
.ps-otpverification .otp-field.has-error {
  border-color: red;
}
 
.success-message {
  color: green;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
 
.error-message {
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
 
.otp-verify-btn {
  background-color: #5b8c68;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 12px 30px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease;
}
 
.otp-verify-btn:hover {
  background-color: #4a7357;
}
 
.ps-otpverification .btn[disabled] {
  cursor: not-allowed;
  opacity: 0.7;
}
 
.ps-otpverification .login-logo {
  background-color: #5b8c68;
  color: #fff;
  padding: 15px 0;
}
.ps-otpverification .spinner-border {
  width: 1rem;
  height: 1rem;
}
.ps-otpverification .forgot-password {
  position: relative;
  display: inline-block;
  margin-top: 20px;
  color: #5b8c68;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s ease;
  cursor: pointer;
}
 
.ps-otpverification .forgot-password::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #5b8c68;
  transition: width 0.3s ease-in-out;
}
 
.ps-otpverification .forgot-password:hover {
  color: #4a7357;
}
 
.ps-otpverification .forgot-password:hover::after {
  width: 100%;
}
 
 