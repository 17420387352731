.ps-login,
.ps-login::before,
.ps-login::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.ps-login {
  height: 100%;
  font-family: Arial, sans-serif;
  font-size: 12px;
  line-height: 1.5;
}

.ps-login #loginbackground-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.ps-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 0 20px;
  font-size: 12px;
}

.login-container {
  max-width: 100%;
  min-width: 400px !important;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
.ps-login .login-logo {
  background-color: #5b8c68;
  color: #fff;
  padding: 15px 0;
}
.Keep-me {
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.ps-login.keepmeremember {
  cursor: pointer;
  transform: scale(1);
  vertical-align: middle;
  accent-color: #5b8c68 !important;
  border-color: #5b8c68 !important;
  margin-bottom: 1%;
}

.login-custom {
  background-color: #f7f9fc;
  padding: 20px;
  font-size: 12px;
}

.ps-login.login-container img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 10px auto;
}

.col-lg-11 {
  display: flex;
  justify-content: center;
}

.ps-login.info-text {
  font-size: 12px;
  color: #5a5a5a;
  margin-bottom: 8px;
}

.ps-login .email-label {
  font-weight: 500;
  color: #333;
  font-size: 12px;
}

.ps-login.email-input,
.ps-login .form-control {
  width: 100%;
  padding: 10px;
  font-size: 12px;
  color: #495057;
  border-radius: 10px;
  border: 1px solid #ced4da;
  transition: border-color 0.2s ease;
}

.ps-login.email-input:focus,
.ps-login .form-control:focus {
  border-color: #b1b1b1;
}

.ps-login.form-group {
  width: 100%;
}

.password-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #5b8c68;
  font-size: 14px;
}

.text-danger {
  color: #d9534f;
  font-size: 12px;
  margin-top: 5px;
}

.ps-login button[type="submit"],
button[type="submit"]:disabled,
button[type="submit"]:disabled:hover {
  width: 100%;
  padding: 10px;
  font-weight: 500;
  background-color: #eaebec;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s ease;
}

.ps-login button[type="submit"]:hover {
  background-color: #f5f6f7;
}

.ps-login a.forgot-password {
  color: #5b8c68;
  text-decoration: none;
  position: relative;
  font-weight: bold;
  font-size: 12px;
  display: inline-block;
}

.ps-login a.forgot-password::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 0;
  background-color: #5b8c68 !important;
  bottom: -2px;
  left: 0;
  transition: width 0.3s ease;
}

.ps-login a.forgot-password:hover::after {
  width: 100%;
}

.keepmeremember {
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #5b8c68 !important;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  outline: none !important;
}

.keepmeremember:checked {
  background-color: #5b8c68 !important;
  border-color: #5b8c68;
}

.keepmeremember:focus {
  outline: none;
}

.keepmeremember:checked::after {
  content: "✔";
  color: rgb(238, 244, 240) !important;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ps-login a.read-more1 {
  display: inline-block;
  color: #5b8c68;
  background: #fff;
  padding: 10px 25px;
  border: 1px solid gray;
  border-radius: 100px;
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 20px;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

.ps-login a.read-more1:hover {
  color: #ffffff;
  background: gray;
  border: 1px solid #5b8c68;
}

.ps-login .read-more1 {
  color: transparent;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 5px 10px;
  text-decoration: none;
}
/* 
@media (max-width: 768px) {
  .ps-login .login-container {
    margin-left: 5%;
    margin-top: 8%;
  }

  .ps-login.k-master-row {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .ps-login .login-container {
    margin-left: 120px;
    margin-top: 8%;
    margin-right: 125px;
  }

  .ps-login.k-master-row {
    font-size: 10px;
  }
} */
@media (max-width: 768px) {
  .ps-login .login-container {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 8%;
  }

  .ps-login.k-master-row {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .ps-login .login-container {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 8%;
  }

  .ps-login.k-master-row {
    font-size: 12px;
  }
}
.ps-login .has-error .email-input {
  border-color: red;
}

.ps-login .has-error input {
  border-color: red !important;
}

.ps-login .is-invalid {
  border-color: red !important;
}

.ps-login .action-buttons {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.ps-login .action-buttons a {
  font-size: 12px;
}

.ps-login .input-error {
  border: 2px solid red !important;
  background-color: #ffe6e6;
}

.ps-login .error-text {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.ps-login .button-align {
  display: flex;
  justify-content: end;
  align-items: center;
}
